module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-49338482-1","head":false,"cookieDomain":"videos.primitiveskate.com","anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":true,"isUsingColorMode":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://primitiveskate.myshopify.com/api/2023-04/graphql.json","headers":{"X-Shopify-Storefront-Access-Token":"1cde0dbc2c658b4ea4021dbb5fe0006d","Accept":"application/json"}},
    }]
